.sidebar {
  color: var(--sidebar-color);
  background: center / cover;
}

.header {
  width: 100%;
  font-family: var(--body-font);
  text-align: center;
  padding: 2em;

  a {
    color: var(--oc-white);
  }
}

.brand-title {
  font-family: var(--brand-title-font);
  font-size: 3.25em;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -1px;
}

.brand-tagline {
  font-size: 1.25em;
  line-height: 1.2;
  letter-spacing: -1px;
}

.nav {
  margin: 1em 0;
}

.nav-item {
  a:hover {
    background-color: transparent;
  }

  .current {
    font-weight: bold;
  }
}

.social-list {
  white-space: normal;
}

.social-item {
  a {
    background-color: transparent;

    &:focus,
    &:hover {
      color: var(--link-color);
      background-color: transparent;
    }
  }
}

@media (min-width: $md-screen) {
  .header {
    font-size: 80%;
    text-align: left;
    position: absolute;
    bottom: 0;
  }

  .nav-item a {
    padding-left: 0;
  }

  .social-item {
    a {
      padding-left: 0;
    }
  }
}

@media (min-width: $lg-screen) {
  .header {
    font-size: 100%;
  }
}
